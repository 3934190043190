body {
  background-color: #1d1f21;
  margin: 0 auto;
  padding: 8em;
  font-family: Oxygen, monospace;
  color: #eee;
  text-align: justify;
}

p {
  color: #eee;
  font-size: 1.1em;
  hyphens: auto;
}

li {
  color: #eee;
  font-size: 1em;
}

time {
  color: #666;
}

.container {
  min-height: max-content;
}

.name {
  color: #2bbc8a;
  font-size: 2em;
  margin: 0;
}

.title {
  color: #666;
  font-size: 1.2em;
}

.about-me-title {
  color: #2bbc8a;
  font-size: 2em;
}

.table {
  width: 100%;
}

.table-title {
  color: #eee;
  margin: 0;
}

.td {
  /* float: left; */
  width: 39.33%;
}

.about-list {
  padding-left: 15px;
}

.blogs {
  margin-top: 30px;
}

.blog-title {
  color: #2bbc8a;
  font-size: 1.5em;
}

.discription {
  font-size: 1em;
  color: #c9cacc;
}

.blog-list {
  margin: 10px;
  font-size: 1.1em;
}

.blog-link {
  color: #c9cacc;
}

.blog-link:hover {
  color: #2bbc8a;
}
.projects {
  margin-top: 30px;
}
.sub-title {
  color: #2bbc8a;
  font-size: 1.5em;
}

.flex-container {
  text-align: justify;
  inherits: initial;
}

.github-icon-container {
  display: flex;
  width: 100%;
}

.githubicon,
.TwitterIcon,
.linkedinIcon {
  padding-top: 17px;
  padding-left: 5px;
}

.table-data {
  display: flex;
}

.icon-style {
  margin-right: 5px;
  margin-top: 2px;
}

.article {
  display: flex;
  width: 100%;
}

.project-image {
  width: 300px;
  height: 200px;
}

.project-description {
  color: #c9cacc;
  font-size: 1em;
}

.data-section {
  padding-top: 30px;
}

.tech-link {
  color: #eee;
}

.certificates-container {
  display: flex;
  width: 100%;
  padding: 0 50px;
}

.figure-certificates {
  display: flex;
  margin: 10px;
}

.certificate-image {
  width: 200px;
  height: 180px;
}

.flex {
  display: flex;
}

.footer {
  color: #666;
  display: flex;
  width: 100%;
  align-content: center;
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.left {
  align-self: flex-start;
  font-size: 0.9em;
}

.right {
  align-self: flex-end;
}

.footer-link {
  text-decoration: underline;
  color: #666;
  font-size: 0.9em;
}

@media all and (max-width: 420px) {
  body {
    padding: 3em;
  }

  p {
    font-size: 0.3em;
    padding: 0;
  }

  li {
    font-size: 0.3em;
  }

  .name {
    font-size: 0.9em;
  }

  .title {
    font-size: 0.4em;
  }

  .sub-title {
    font-size: 0.7em;
    margin: 0;
  }

  .icon-twetter-wrapper {
    padding-top: 19px;
  }

  .githubicon,
  .TwitterIcon,
  .linkedinIcon {
    padding-top: 7px;
    padding-left: 5px;
    width: 10px;
    height: 7px;
  }

  .TwitterIcon {
    padding-top: 18px;
  }

  .td {
    font-size: 0.3em;
  }

  .tr {
    width: 20%;
  }

  .table-data {
    font-size: 0.3em;
    margin-top: 10px;
  }

  .icon-style {
    width: 7px;
    height: 7px;
  }

  .blogs {
    margin-top: 0;
  }

  .discription {
    font-size: 10px;
  }

  .blog-list {
    font-size: 0.3em;
  }

  time {
    font-size: 0.3em;
  }

  .blog-link {
    font-size: 0.3em;
  }

  .projects {
    margin-top: 20px;
  }

  .article {
    display: block;
  }

  .left {
    margin: 0;
  }

  .project-image {
    width: 250px;
  }

  .data-section {
    padding-top: 0;
    margin: 10px;
  }

  .certificates-container {
    display: block;
    padding: 0;
    margin-bottom: 30px;
  }

  .description {
    font-size: 30px;
  }

  .left {
    font-size: 0.4em;
  }

  .right {
    font-size: 0.4em;
  }
}

@media all and (max-width: 530px) {
  body {
    padding: 3em;
  }

  p {
    font-size: 0.5em;
    padding: 0;
  }

  li {
    font-size: 0.5em;
  }

  .name {
    font-size: 0.9em;
  }

  .title {
    font-size: 0.4em;
  }

  .sub-title {
    font-size: 0.7em;
    margin: 0;
  }

  .githubicon,
  .TwitterIcon,
  .linkedinIcon {
    padding-top: 7px;
    padding-left: 5px;
    width: 10px;
    height: 7px;
  }

  .td {
    font-size: 0.3em;
  }

  .tr {
    width: 20%;
  }

  .table-data {
    font-size: 0.3em;
    margin-top: 10px;
  }

  .icon-style {
    width: 7px;
    height: 7px;
  }

  .blogs {
    margin-top: 0;
  }

  .discription {
    font-size: 10px;
  }

  .blog-list {
    font-size: 0.3em;
  }

  time {
    font-size: 0.3em;
  }

  .blog-link {
    font-size: 0.3em;
  }

  .projects {
    margin-top: 20px;
  }

  .article {
    display: block;
  }

  .left {
    margin: 0;
  }

  .project-image {
    width: 250px;
  }

  .data-section {
    padding-top: 0;
    margin: 10px;
  }

  .certificates-container {
    display: block;
    padding: 0;
    margin-bottom: 30px;
  }

  .description {
    font-size: 30px;
  }

  .left {
    font-size: 0.4em;
  }

  .right {
    font-size: 0.4em;
  }
}

@media all and (max-width: 760px) {
  body {
    padding: 3em;
  }

  p {
    font-size: 25px;
    padding: 0;
  }

  li {
    font-size: 15px;
  }

  .name {
    font-size: 35px;
  }

  .title {
    font-size: 20px;
  }

  .sub-title {
    font-size: 30px;
    margin: 0;
  }

  .table-title {
    font-size: 15px;
  }

  .githubicon,
  .TwitterIcon,
  .linkedinIcon {
    padding-top: 10px;
    padding-left: 0;
  }

  .td {
    font-size: 15px;
  }

  .tr {
    width: 20%;
  }

  .table-data {
    font-size: 0.3em;
    margin-top: 10px;
  }

  .icon-style {
    width: 7px;
    height: 7px;
  }

  .blogs {
    margin-top: 0;
  }

  .discription {
    font-size: 20px;
  }

  .blog-list {
    font-size: 0.3em;
  }

  time {
    font-size: 20px;
  }

  .blog-link {
    font-size: 20px;
  }

  .projects {
    margin-top: 20px;
  }

  .article {
    display: block;
  }

  .left {
    margin: 0;
  }

  .project-image {
    width: 300px;
    margin: 20px;
  }

  .data-section {
    padding-top: 0;
    margin: 20px;
  }

  .certificates-container {
    display: block;
    padding: 0;
    margin-bottom: 30px;
  }

  .description {
    font-size: 30px;
  }

  .left {
    font-size: 0.4em;
  }

  .right {
    font-size: 0.4em;
  }
}
